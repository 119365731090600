import React, { useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from '../../lib/nodash';
import { Box } from 'grommet';

import FormRegisterIndustryMember from './FormRegisterIndustryMember';
import MemberSetupPending from './MemberSetupPending';
import MemberSetupComplete from './MemberSetupComplete';
import { setActiveModal } from '../../state/ui/uiSlice';
import useModalNavigation from '../useModalNavigation';

import {
  setTemporaryToken,
  setJwt,
  setMember,
} from '../../state/industry/industrySlice';
import selectMemberName from '../../state/industry/selectMemberName';

const IndustryMemberRegistration = ({
  partnerName,
  mailingListId,
  memberGroup,
  receiveFandeck,
  returnTo,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState('apply');
  const memberName = useSelector(selectMemberName);
  const { navigate } = useModalNavigation();

  const handleSignupSuccess = useCallback(
    ({ token }) => {
      dispatch(setTemporaryToken(token));
      setStep('pending');
    },
    [dispatch, setTemporaryToken, setStep]
  );

  const handleSetupSuccess = useCallback(
    ({ token, member }) => {
      dispatch(setJwt(token));
      dispatch(setMember(member));
      if (returnTo) {
        navigate(returnTo);
        dispatch(setActiveModal({ story: returnTo.id }));
      } else {
        setStep('success');
      }
    },
    [dispatch, setJwt, setMember]
  );

  return (
    <Box
      pad="medium"
      fill="horizontal"
      justify="center"
      className="content-container"
    >
      {isEqual('apply', step) && (
        <Box>
          <FormRegisterIndustryMember
            onSuccess={handleSignupSuccess}
            partnerName={partnerName}
            mailingListId={mailingListId}
            memberGroup={memberGroup}
            receiveFandeck={receiveFandeck}
          />
        </Box>
      )}
      {isEqual('pending', step) && (
        <Box fill>
          <MemberSetupPending onSuccess={handleSetupSuccess} />
        </Box>
      )}
      {isEqual('success', step) && <MemberSetupComplete name={memberName} />}
    </Box>
  );
};

IndustryMemberRegistration.propTypes = {
  partnerName: PropTypes.string.isRequired,
  mailingListId: PropTypes.string,
  memberGroup: PropTypes.string,
  receiveFandeck: PropTypes.bool,
  returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(IndustryMemberRegistration);
