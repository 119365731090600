import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box, Heading } from 'grommet';
import { isNil, get } from '../../lib/nodash';

import Loading from '../Loading';
import { GET_FINAL_MEMBER_JWT } from '../../queries/industryQueries';

const MemberSetupPending = ({ onSuccess }) => {
  const temp_token = useSelector((state) => state.industry.temp_token);
  const { data, stopPolling } = useQuery(GET_FINAL_MEMBER_JWT, {
    variables: {
      token: temp_token,
    },
    pollInterval: 1000,
    skip: !temp_token,
  });
  useEffect(() => {
    if (!isNil(get('finalMemberJwt.token', data))) {
      stopPolling();
      onSuccess(get('finalMemberJwt', data));
    }
  }, [data]);
  return (
    <Box align="center" justify="center" pad="medium" gap="medium">
      <Loading size="large" />
      <Heading level={3}>Please wait while we set up your account</Heading>
    </Box>
  );
};

MemberSetupPending.propTypes = {
  onSuccess: PropTypes.func,
};

export default MemberSetupPending;
