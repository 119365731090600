import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useMutation } from '@apollo/client';
import {
  Anchor,
  Box,
  Button,
  RadioButtonGroup,
  MaskedInput,
  Text,
} from 'grommet';
import styled from 'styled-components';
import { isEqual, get, head } from '../../lib/nodash';

import usStates from '../../lib/usStates';
import FieldInputWithErrors from '../Inputs/FieldInputWithErrors';
import InputText from '../Inputs/InputText';
import InputSelect from '../Inputs/InputSelect';
import { REGISTER_MEMBER } from '../../queries/industryQueries';

const RadioContainer = styled(Box)`
  input {
    padding: 0 0.5em 0 0.5em;
  }
`;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required Field*'),
  last_name: Yup.string().required('Required Field*'),
  email: Yup.string().email('Invalid email').required('Required Field*'),
  phone: Yup.string()
    .min(14, 'Invalid Phone Number')
    .required('Required Field*'),
  business_address1: Yup.string().trim().required('Required Field*'),
  business_name: Yup.string(),
  business_city: Yup.string().required('Required Field*'),
  business_state: Yup.string().required('Required Field*'),
  business_zip: Yup.string().required('Required Field*'),
  social_handle: Yup.string(),
  password: Yup.string()
    .trim()
    .required('Please set a password so you can login later'),
});

const FormRegisterIndustryMember = ({
  onSuccess,
  onLogin,
  partnerName,
  mailingListId,
  memberGroup,
  receiveFandeck,
}) => {
  const [stateOptions, setStateOptions] = useState(
    usStates.map((x) => x.abbreviation)
  );

  const [registerMember] = useMutation(REGISTER_MEMBER);

  const handleSearch = (text) => {
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    const exp = new RegExp(escapedText, 'i');
    setStateOptions(
      usStates
        .filter((o) => exp.test(o.abbreviation))
        .map((x) => x.abbreviation)
    );
  };

  return (
    <Box fill="horizontal">
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          business_address1: '',
          business_address2: '',
          business_city: '',
          business_state: '',
          business_zip: '',
          phone: '',
          allows_sms_contact: 'Yes',
          social_handle: '',
          member_group: memberGroup,
          shopify_tags: [partnerName],
          shopify_note: `Backdrop x ${partnerName} signup`,
          password: '',
          mailing_list_id: mailingListId,
          receive_fandeck: receiveFandeck,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          const parsedValues = {
            ...values,
            allows_sms_contact: values.allows_sms_contact === 'Yes',
          };
          setSubmitting(true);
          try {
            const response = await registerMember({ variables: parsedValues });
            onSuccess(get('data.registerMember', response));
          } catch (e) {
            setStatus({ error: e });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, status, handleSubmit, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box gap="medium">
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="First Name"
                      type="text"
                      name="first_name"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Last Name"
                      type="text"
                      name="last_name"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Work Email Address"
                      type="email"
                      name="email"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={MaskedInput}
                      mask={[
                        { fixed: '(' },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: 'xxx',
                        },
                        { fixed: ')' },
                        { fixed: ' ' },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: 'xxx',
                        },
                        { fixed: '-' },
                        {
                          length: 4,
                          regexp: /^[0-9]{1,4}$/,
                          placeholder: 'xxxx',
                        },
                      ]}
                      label="Phone"
                      type="tel"
                      name="phone"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Shipping Address"
                      type="text"
                      name="business_address1"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Shipping Address 2"
                      type="text"
                      name="business_address2"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="City"
                      type="text"
                      name="business_city"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      label="State"
                      name="business_state"
                      options={stateOptions}
                      onSearch={handleSearch}
                      component={FieldInputWithErrors}
                      inputComponent={InputSelect}
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Zip Code"
                      type="text"
                      name="business_zip"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Social Media Handle"
                      type="text"
                      name="social_handle"
                      placeholder="@"
                    />
                  </Box>
                  <RadioContainer flex>
                    <Field
                      name="allows_sms_contact"
                      component={FieldInputWithErrors}
                      inputComponent={RadioButtonGroup}
                      options={['Yes', 'No']}
                      direction="row"
                      fill="vertical"
                      label="Can we contact you via SMS?"
                      style={{ marginTop: '8px' }}
                    />
                  </RadioContainer>
                </Box>
                <Box>
                  <Field
                    name="password"
                    component={FieldInputWithErrors}
                    type="password"
                    label="Set a password"
                    style={{ marginTop: '8px' }}
                  />
                </Box>
                <Box className="actions">
                  <Field type="hidden" name="industry" />
                  <Field type="hidden" name="member_group" />
                  <Field type="hidden" name="shopify_tags" />
                  <Field type="hidden" name="shopify_note" />
                  <Field type="hidden" name="mailing_list_id" />
                  {status && status.error && (
                    <Box
                      className="form-errors"
                      margin={{ vertical: 'medium' }}
                    >
                      <Text color="status-error">
                        We&apos;re sorry, something went wrong.
                      </Text>
                      {isEqual(
                        'members_email_unique',
                        get(
                          'extensions.exception.constraint',
                          head(get('error.graphQLErrors', status))
                        )
                      ) ||
                        (isEqual(
                          'members_email_key',
                          get(
                            'extensions.exception.constraint',
                            head(get('error.graphQLErrors', status))
                          )
                        ) && (
                          <Text color="status-error">
                            There is already an Industry by Backdrop member with
                            this email address.{' '}
                            <Anchor
                              color="status-error"
                              href="#industry_login"
                              onClick={onLogin}
                            >
                              Login
                            </Anchor>
                            .
                          </Text>
                        ))}
                    </Box>
                  )}
                  <Button
                    style={{ height: '61px' }}
                    primary
                    type="submit"
                    label="Sign Up"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !isValid}
                  />
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormRegisterIndustryMember.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  partnerName: PropTypes.string.isRequired,
  mailingListId: PropTypes.string,
  memberGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  receiveFandeck: PropTypes.bool,
};

export default memo(FormRegisterIndustryMember);
