import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from 'grommet';
import { get } from '../../lib/nodash';

const MemberSetupComplete = () => {
  const name = useSelector((state) => get('industry.member.first_name', state));
  return (
    <Box gap="medium">
      <Heading level={3}>Thanks, {name}!</Heading>
      <Text>We&apos;re applying your discounted pricing.</Text>
    </Box>
  );
};

MemberSetupComplete.propTypes = {
  name: PropTypes.string,
};

export default MemberSetupComplete;
